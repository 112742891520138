<template>
  <div class="request-invoice">
    <div class="stepbox">
      <div>
        <p>1.开票订单选择</p>
        <div class="threeth"></div>
      </div>
      <div>
        <p>2.确定发票信息和地址</p>
        <div class="threeth"></div>
      </div>
    </div>
    <div class="requestInvoice">
      <div>
        <div class="tit">
          <span>开票明细</span>
          <a @click="showPartFn" href="javascript:;">修改发票信息</a>
        </div>
        <div class="cont">
          <div class="left">
            <div>发票金额：￥{{ price }}</div>
            <div>发票抬头：{{ InvoiceData && InvoiceData.receipt_title }}</div>
            <div>联系电话：{{ address && address.receive_phone }}</div>
          </div>
          <div class="right">
            <div v-if="InvoiceData">
              发票类型：{{ InvoiceData.receipt_type === "ELECTRO" ? "电子发票"
              : InvoiceData.receipt_type === 'VATOSPECIAL' ? "增值税专用发票" : "增值税普通发票" }}
            </div>
            <div v-else>
              发票类型：增值税普通发票1
            </div>
            <div>收货人：{{ address && address.receive_name }}</div>
            <div>收取地址：{{ address && address.receive_addr }}</div>
          </div>
          <div class="btn">
            <el-button @click.once="submits()" type="primary">确认并提交</el-button>
          </div>
        </div>
      </div>
      <div v-if="is === '1'" class="tables">
        <div class="table_tit">订单信息</div>
        <el-table
          :header-cell-style="{
            textAlign: 'center',
            backgroundColor: 'rgba(230, 236, 247, 1)',
          }"
          style="width: 100%; text-align: center"
          ref="orderinvoiceList"
          :data="orderinvoiceList"
          border
        >
          <el-table-column prop="trade_sn" label="关联总订单号"></el-table-column>
          <el-table-column prop="sn" label="订单号"></el-table-column>
          <el-table-column prop="complete_time" label="完成时间" width="160">
            <template slot-scope="scope">
              {{ scope.row.complete_time | unixToDate("yyyy-MM-dd hh:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column label="商品名称 x 购买数量 / 商品单价">
            <template slot-scope="scope">
              <div v-for="item in scope.row.items_json" :key="item.seller_id">
                {{ item.name }} x {{ item.num }} 单价: {{ item.original_price }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="order_price" label="总价"
            ><template slot-scope="scope">
              {{ scope.row.order_price | unitPrice }}</template
            >
          </el-table-column>
          <el-table-column prop="order_price" label="可开具金额"
            ><template slot-scope="scope">
              {{ scope.row.order_price - scope.row.order_refund_price | unitPrice  }}</template
            >
          </el-table-column>
        </el-table>
        <div class="pages">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-size="paramsData.page_size"
            :page-sizes="MixinPageSizes"
            :layout="MixinTableLayout"
            background
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <div v-if="is === '2'" class="tables">
        <div class="table_tit">订单信息</div>
        <el-table
          :header-cell-style="{
            textAlign: 'center',
            backgroundColor: 'rgba(230, 236, 247, 1)',
          }"
          style="width: 100%; text-align: center"
          :data="serviceinvoiceList"
          border
        >
          <el-table-column prop="trade_sn" label="关联总订单号"></el-table-column>
          <el-table-column prop="sn" label="订单号"></el-table-column>
          <el-table-column prop="create_time" label="完成时间" width="160">
            <template slot-scope="scope">
              {{ scope.row.create_time | unixToDate("yyyy-MM-dd hh:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column
            prop="service_name"
            label="服务名称"
          ></el-table-column>
          <el-table-column prop="price" label="可开具金额">
            <template slot-scope="scope">
              {{ scope.row.price | unitPrice }}</template
            >
          </el-table-column>
        </el-table>
        <div class="pages">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-size="paramsData.page_size"
            :page-sizes="MixinPageSizes"
            :layout="MixinTableLayout"
            background
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <div v-if="is === '3'" class="tables">
        <div class="table_tit">订单信息</div>
        <el-table
          :header-cell-style="{
            textAlign: 'center',
            backgroundColor: 'rgba(230, 236, 247, 1)',
          }"
          style="width: 100%; text-align: center"
          :data="platFormInvoiceList"
          border
        >
          <el-table-column prop="trade_sn" label="关联总订单号" align="center"></el-table-column>
          <el-table-column prop="create_time" label="完成时间" align="center" width="160">
            <template slot-scope="scope">
              {{ scope.row.create_time | unixToDate("yyyy-MM-dd hh:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column prop="platform_freight_price" align="center" label="运费金额">
            <template slot-scope="scope">
              {{ scope.row.platform_freight_price | unitPrice }}
            </template>
          </el-table-column>
          <el-table-column prop="platform_freight_price" align="center" label="可开具金额">
            <template slot-scope="scope">
              {{ scope.row.platform_freight_price | unitPrice }}
            </template>
          </el-table-column>
        </el-table>
        <div class="pages">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-size="paramsData.page_size"
            :page-sizes="MixinPageSizes"
            :layout="MixinTableLayout"
            background
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as API_goods from "@/api/goods";
export default {
  data() {
    return {
      InvoiceData: {},
      is: "",
      id: "",
      price: 0,
      orderinvoiceList: [],
      serviceinvoiceList: [],
      platFormInvoiceList: [],
      paramsData: {
        page_no: 1,
        page_size: 20,
        startTime: "",
        endTime: "",
        step: 2,
        invoice_check: 1,
      },
      total: 0,

      currentPage4: 1,
      address: {}
    };
  },
  mounted() {
    this.is = this.$route.query.is;
    this.id = this.$route.query.id;
    this.price = this.$route.query.price;
    this.getDefaultShopReceiptAddress();
    this.getInvoiceTitleDetail();

    if (this.is === "1") {
      this.getWaitReceiptOrderList();
    }
    if (this.is === "2") {
      this.getShopReceipt();
    }
    if (this.is === "3") {
      this.getPlatFormFreight()
    }
  },
  methods: {
    handleSizeChange(size) {
      this.paramsData.page_size = size;
      if (this.is === "1") {
        this.getWaitReceiptOrderList();
      } else {
        this.getShopReceipt();
      }
    },
    handleCurrentChange(page) {
      this.paramsData.page_no = page;
      if (this.is === "1") {
        this.getWaitReceiptOrderList();
      } else {
        this.getShopReceipt();
      }
    },
    showPartFn() {
      this.$router.push({ path: "/finance/Invoicemanger/Invoicemangerdetail" });
    },
    getInvoiceTitleDetail() {
      // 搜索发票抬头信息
      API_goods.getInvoiceTitleDetail().then((res) => {
        this.InvoiceData = res.shop_receipt
        this.address = res.address
      });
    },
    getDefaultShopReceiptAddress() {
      API_goods.getDefaultShopReceiptAddress().then((res) => {
        for (let j in res) {
          this.$set(this.InvoiceData, j, res[j]);
        }
      });
    },
    getWaitReceiptOrderList() {
      API_goods.getWaitReceiptOrderList(this.paramsData).then((res) => {
        this.total = res.data_total;
        this.orderinvoiceList = res.data;
        this.orderinvoiceList.map((item, index) => {
          this.orderinvoiceList[index].items_json = JSON.parse(
            this.orderinvoiceList[index].items_json
          );
        });
      });
    },
    getShopReceipt() {
      API_goods.getShopReceipt(this.paramsData).then((res) => {
        this.total = res.data_total;
        this.serviceinvoiceList = res.data;
      });
    },
    getPlatFormFreight() {
      API_goods.getPlatFormFreight(this.paramsData).then(response => {
        this.platFormInvoiceList = response.data;
        this.total = response.data_total;
      })
    },
    submits() {
      if (this.is === "1") {
        API_goods.getWaitReceiptOrderListaddHistory({
          is_all: 0,
          step: 2,
          order_ids: [0],
        }).then((res) => {
          this.$message.success("提交成功");
          this.$router.push({ path: "/finance/Invoicemanger?type=1" });
        });
      } else if (this.is === "2") {
        API_goods.getShopReceiptaddHistoryService({
          is_all: 0,
          step: 2,
          ids: [0],
        }).then((res) => {
          this.$message.success("提交成功");
          this.$router.push({ path: "/finance/Invoicemanger?type=2" });
        });
      } else if (this.is === "3") {
        API_goods.addHistoryFreight({
          is_all: 0,
          step: 2,
          trade_ids: [0],
        }).then(res => {
          this.$message.success("提交成功");
          this.$router.push({ path: "/finance/Invoicemanger?type=3" });
        })
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.stepbox {
  background: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 20px;
  display: -webkit-flex;
  justify-content: center;
  padding: 20px 0;
  > div {
    display: inline-block;
    width: 380px;
    height: 48px;
    text-align: center;
    p {
      width: 340px;
      height: 48px;
      background: #1a43a9;
      line-height: 48px;
      margin: 0;
      text-align: center;
      color: #ffffff;
      float: left;
    }
    div {
      float: left;
      width: 0;
      height: 0;
      border-left: 14px solid #1a43a9;
      border-top: 24px solid #ffffff;
      border-bottom: 24px solid #ffffff;
    }
    .threeths {
      border-left: 14px solid #ffffff;
      border-top: 24px solid #1a43a9;
      border-bottom: 24px solid #1a43a9;
    }
  }
  .activediv {
    p {
      background: #c0c4cc;
    }
    .threeths {
      border-top: 24px solid #c0c4cc;
      border-bottom: 24px solid #c0c4cc;
      border-left: 14px solid #ffffff;
    }
    div {
      float: left;
      border-left: 14px solid #c0c4cc;
      border-top: 24px solid #ffffff;
      border-bottom: 24px solid #ffffff;
    }
  }
}
.requestInvoice {
  & > div {
    width: 100%;
    padding: 20px;
    background: #fff;
    border-radius: 4px;
  }
  .tit {
    span {
      font-size: 18px;
      color: #666;
    }
    a {
      color: #0099ff;
    }
  }
  .cont {
    margin-top: 20px;
    display: -webkit-flex;
    & > div {
      width: 50%;
      color: #666;
    }
    .left > div,
    .right > div {
      margin-bottom: 20px;
    }
    .btn {
      text-align: center;
      margin-top: 20px;
    }
  }
  .tables {
    margin-top: 20px;
    padding: 20px;
    .table_tit {
      font-size: 18px;
      color: #666;
      margin-bottom: 20px;
    }
    .pages {
      margin-top: 20px;
    }
    .btn {
      text-align: center;
      margin-top: 20px;
    }
  }
}
</style>
