var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"request-invoice"},[_vm._m(0),_c('div',{staticClass:"requestInvoice"},[_c('div',[_c('div',{staticClass:"tit"},[_c('span',[_vm._v("开票明细")]),_c('a',{attrs:{"href":"javascript:;"},on:{"click":_vm.showPartFn}},[_vm._v("修改发票信息")])]),_c('div',{staticClass:"cont"},[_c('div',{staticClass:"left"},[_c('div',[_vm._v("发票金额：￥"+_vm._s(_vm.price))]),_c('div',[_vm._v("发票抬头："+_vm._s(_vm.InvoiceData && _vm.InvoiceData.receipt_title))]),_c('div',[_vm._v("联系电话："+_vm._s(_vm.address && _vm.address.receive_phone))])]),_c('div',{staticClass:"right"},[(_vm.InvoiceData)?_c('div',[_vm._v(" 发票类型："+_vm._s(_vm.InvoiceData.receipt_type === "ELECTRO" ? "电子发票" : _vm.InvoiceData.receipt_type === 'VATOSPECIAL' ? "增值税专用发票" : "增值税普通发票")+" ")]):_c('div',[_vm._v(" 发票类型：增值税普通发票1 ")]),_c('div',[_vm._v("收货人："+_vm._s(_vm.address && _vm.address.receive_name))]),_c('div',[_vm._v("收取地址："+_vm._s(_vm.address && _vm.address.receive_addr))])]),_c('div',{staticClass:"btn"},[_c('el-button',{attrs:{"type":"primary"},on:{"~click":function($event){return _vm.submits()}}},[_vm._v("确认并提交")])],1)])]),(_vm.is === '1')?_c('div',{staticClass:"tables"},[_c('div',{staticClass:"table_tit"},[_vm._v("订单信息")]),_c('el-table',{ref:"orderinvoiceList",staticStyle:{"width":"100%","text-align":"center"},attrs:{"header-cell-style":{
          textAlign: 'center',
          backgroundColor: 'rgba(230, 236, 247, 1)',
        },"data":_vm.orderinvoiceList,"border":""}},[_c('el-table-column',{attrs:{"prop":"trade_sn","label":"关联总订单号"}}),_c('el-table-column',{attrs:{"prop":"sn","label":"订单号"}}),_c('el-table-column',{attrs:{"prop":"complete_time","label":"完成时间","width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("unixToDate")(scope.row.complete_time,"yyyy-MM-dd hh:mm:ss"))+" ")]}}],null,false,1182955246)}),_c('el-table-column',{attrs:{"label":"商品名称 x 购买数量 / 商品单价"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return _vm._l((scope.row.items_json),function(item){return _c('div',{key:item.seller_id},[_vm._v(" "+_vm._s(item.name)+" x "+_vm._s(item.num)+" 单价: "+_vm._s(item.original_price)+" ")])})}}],null,false,2115238749)}),_c('el-table-column',{attrs:{"prop":"order_price","label":"总价"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("unitPrice")(scope.row.order_price)))]}}],null,false,2333418488)}),_c('el-table-column',{attrs:{"prop":"order_price","label":"可开具金额"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("unitPrice")(scope.row.order_price - scope.row.order_refund_price)))]}}],null,false,2526975096)})],1),_c('div',{staticClass:"pages"},[_c('el-pagination',{attrs:{"current-page":_vm.currentPage4,"page-size":_vm.paramsData.page_size,"page-sizes":_vm.MixinPageSizes,"layout":_vm.MixinTableLayout,"background":"","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],1):_vm._e(),(_vm.is === '2')?_c('div',{staticClass:"tables"},[_c('div',{staticClass:"table_tit"},[_vm._v("订单信息")]),_c('el-table',{staticStyle:{"width":"100%","text-align":"center"},attrs:{"header-cell-style":{
          textAlign: 'center',
          backgroundColor: 'rgba(230, 236, 247, 1)',
        },"data":_vm.serviceinvoiceList,"border":""}},[_c('el-table-column',{attrs:{"prop":"trade_sn","label":"关联总订单号"}}),_c('el-table-column',{attrs:{"prop":"sn","label":"订单号"}}),_c('el-table-column',{attrs:{"prop":"create_time","label":"完成时间","width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("unixToDate")(scope.row.create_time,"yyyy-MM-dd hh:mm:ss"))+" ")]}}],null,false,950046307)}),_c('el-table-column',{attrs:{"prop":"service_name","label":"服务名称"}}),_c('el-table-column',{attrs:{"prop":"price","label":"可开具金额"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("unitPrice")(scope.row.price)))]}}],null,false,280368585)})],1),_c('div',{staticClass:"pages"},[_c('el-pagination',{attrs:{"current-page":_vm.currentPage4,"page-size":_vm.paramsData.page_size,"page-sizes":_vm.MixinPageSizes,"layout":_vm.MixinTableLayout,"background":"","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],1):_vm._e(),(_vm.is === '3')?_c('div',{staticClass:"tables"},[_c('div',{staticClass:"table_tit"},[_vm._v("订单信息")]),_c('el-table',{staticStyle:{"width":"100%","text-align":"center"},attrs:{"header-cell-style":{
          textAlign: 'center',
          backgroundColor: 'rgba(230, 236, 247, 1)',
        },"data":_vm.platFormInvoiceList,"border":""}},[_c('el-table-column',{attrs:{"prop":"trade_sn","label":"关联总订单号","align":"center"}}),_c('el-table-column',{attrs:{"prop":"create_time","label":"完成时间","align":"center","width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("unixToDate")(scope.row.create_time,"yyyy-MM-dd hh:mm:ss"))+" ")]}}],null,false,950046307)}),_c('el-table-column',{attrs:{"prop":"platform_freight_price","align":"center","label":"运费金额"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("unitPrice")(scope.row.platform_freight_price))+" ")]}}],null,false,3772116734)}),_c('el-table-column',{attrs:{"prop":"platform_freight_price","align":"center","label":"可开具金额"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm._f("unitPrice")(scope.row.platform_freight_price))+" ")]}}],null,false,3772116734)})],1),_c('div',{staticClass:"pages"},[_c('el-pagination',{attrs:{"current-page":_vm.currentPage4,"page-size":_vm.paramsData.page_size,"page-sizes":_vm.MixinPageSizes,"layout":_vm.MixinTableLayout,"background":"","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],1):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stepbox"},[_c('div',[_c('p',[_vm._v("1.开票订单选择")]),_c('div',{staticClass:"threeth"})]),_c('div',[_c('p',[_vm._v("2.确定发票信息和地址")]),_c('div',{staticClass:"threeth"})])])
}]

export { render, staticRenderFns }